
  .create-study {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }
  
  .create-study__header {
    font-size: 2em;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
  }
  
  .create-study__input-group {
    margin-bottom: 20px;
  }
  
  .create-study__label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #555;
  }
  
  .create-study__input,
  .create-study__textarea,
  .create-study__select,
  .create-study__file-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    transition: border-color 0.3s ease;
  }
  
  .create-study__input:focus,
  .create-study__textarea:focus,
  .create-study__select:focus,
  .create-study__file-input:focus {
    border-color: #333;
    outline: none;
  }
  
  .create-study__input-row {
    display: flex;
    justify-content: space-between;
  }
  
  .create-study__input-group {
    flex: 1;
    margin-right: 10px;
  }
  
  .create-study__input-group:last-child {
    margin-right: 0;
  }
  
  .create-study__checkbox-group {
    display: flex;
    flex-wrap: wrap;
  }
  
  .create-study__checkbox-item {
    margin-right: 20px;
    display: flex;
    align-items: center;
  }
  
  .create-study__checkbox-item input {
    margin-right: 5px;
  }
  
  .create-study__error-message {
    color: red;
    text-align: center;
    margin: 15px 0;
  }
  
  .create-study__submit-button {
    background-color: #333; /* Dark shade for the button */
    color: #ffffff;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease, transform 0.3s ease;
    display: block;
    width: 100%;
  }
  
  .create-study__submit-button:hover {
    background-color: #555; /* Lighter shade on hover */
    transform: translateY(-2px);
  }
  
  .create-study__submit-button:disabled {
    background-color: #888; /* Disabled button color */
    cursor: not-allowed;
  }
  
  .create-study__image-preview {
    margin-top: 15px;
    max-width: 100%;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  

  .create-study__input--no-arrows::-webkit-outer-spin-button,
.create-study__input--no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.create-study__input--no-arrows {
  -moz-appearance: textfield;
}
