/* ManagePayout.css */

.manage-user-payout {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .user-payout-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .user-payout-table th, .user-payout-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .user-payout-table th {
    background-color: #f4f4f4;
    color: #333;
  }
  
  .user-payout-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .user-payout-table tbody tr:hover {
    background-color: #f1f1f1;
  }
  
  .status-requested {
    color: #ff9800; /* Orange color for 'requested' status */
    background-color: #fff3e0; /* Light orange background */
  }
  
  .status-approved {
    color: #4caf50; /* Green color for 'approved' status */
    background-color: #e8f5e9; /* Light green background */
  }
  
  .status-not-requested {
    color: #9e9e9e; /* Gray color for 'not requested' status */
    background-color: #e0e0e0; /* Light gray background */
  }
  
  .payment-select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #fff;
  }
  
  .btn-info {
    background-color: #333; /* Blue color for the button */
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .btn-info:hover {
    background-color: #555; /* Slightly lighter shade of black for hover effect */
    transform: scale(1.05);
  }
  
  .btn-info:active {
    background-color: #111; /* Darker shade of black for active effect */
    transform: scale(0.95);
  }
  
  .btn-info:disabled {
    background-color: #ccc; /* Gray color for disabled button */
    cursor: not-allowed;
  }
  

  .sendmail {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .sendmail input[type="email"] {
    flex-grow: 1;
    padding: 10px;
    font-size: 16px;
    border: 2px solid #ddd;
    border-radius: 4px;
    transition: border-color 0.3s ease;
  }
  
  .sendmail input[type="email"]:focus {
    border-color: #000000;
    outline: none;
  }
  
 
  
 
  
  @media (max-width: 600px) {
    .sendmail {
      flex-direction: column;
      gap: 15px;
    }
  
    .sendmail input[type="email"] {
      width: 100%;
    }
  
    .sendmail .btn {
      width: 100%;
      text-align: center;
    }
  }
  

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .modal h2 {
    margin-top: 0;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .modal p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .btn-secondary {
    background-color: #6c757d;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    margin-left: 10px;
  }
  
  .btn-secondary:hover {
    background-color: #5a6268;
  }
  
  .popup-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .modal-content {
    text-align: center;
  }
  

  .custom-giftcard-dropdown {
    width: 180px; /* Fixed width */
    padding: 10px; /* Padding for spacing */
    border: 1px solid #ccc; /* Border for a clean look */
    border-radius: 5px; /* Rounded corners */
    background-color: #f9f9f9; /* Light background */
    font-size: 14px; /* Adjust font size */
    color: #333; /* Text color */
    appearance: none; /* Remove default browser styles */
    transition: all 0.3s ease; /* Smooth transition on hover and focus */
    cursor: pointer; /* Pointer cursor for better UX */
    outline: none; /* Remove outline on focus */
  }
  
  /* Add a dropdown arrow */
  .custom-giftcard-dropdown::after {
    content: "▼";
    position: absolute;
    right: 10px;
    pointer-events: none;
    color: #666;
  }
  
  /* Hover effect */
  .custom-giftcard-dropdown:hover {
    border-color: #007bff; /* Highlight border on hover */
    background-color: #eaf4ff; /* Slightly different background */
  }
  
  /* Focus effect */
  .custom-giftcard-dropdown:focus {
    border-color: #007bff; /* Blue border on focus */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Subtle shadow effect */
  }
  
  /* Scrollbar customization */
  .custom-giftcard-dropdown::-webkit-scrollbar {
    width: 8px;
  }
  
  .custom-giftcard-dropdown::-webkit-scrollbar-thumb {
    background: #007bff; /* Blue scrollbar thumb */
    border-radius: 10px; /* Rounded scrollbar */
  }
  
  .custom-giftcard-dropdown::-webkit-scrollbar-thumb:hover {
    background: #0056b3; /* Darker blue on hover */
  }
  
  .custom-giftcard-dropdown::-webkit-scrollbar-track {
    background: #f0f0f0; /* Light track background */
  }
  