.manage-studies {
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: #f5f5f5;
}

.studies-list {
  display: flex;

  flex-wrap: wrap;
  align-content: space-between;
  gap: 20px;
}

.study-card {
  width: 500px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  box-sizing: border-box;
}

.pie-chart-container {
  margin: 20px 0;
}

.study-actions {
  display: flex;
  gap: 10px;
}



.error-message {
  color: red;
  margin-top: 20px;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 400px;
  z-index: 1000;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.modal h2 {
  margin-top: 0;
}

.modal input,
.modal textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.modal button{
  padding: 8px 10px;
  margin-right: 5px;
}


.study-card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
 
  overflow: hidden; /* Hide overflow content */
  display: flex;
  flex-direction: column;
  transition: transform 0.3s;
  
}

.study-card:hover {
  transform: scale(1.01);
}

.study-card h2 {
  font-size: 1.5em;
  color: #007bff;
  /* margin-bottom: 10px; */
}

.study-card p {
  color: #666;
  margin: 5px 0;
}
