.create-contract-container {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.create-contract-title {
  text-align: center;
  margin-bottom: 20px;
}

.create-contract-form {
  display: flex;
  flex-direction: column;
}

.create-contract-field {
  margin-bottom: 15px;
}

.create-contract-label {
  margin-bottom: 5px;
  font-weight: bold;
}

.create-contract-input,
.create-contract-textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.create-contract-textarea {
  resize: vertical;
}

.create-contract-signature {
  margin-top: 20px;
}

.create-contract-signatureCanvas {
  border: 1px solid #ccc;
  border-radius: 4px;
}

.create-contract-signature-buttons {
  margin-top: 10px;
}

.create-contract-save-button,
.create-contract-clear-button,
.create-contract-submit-button {
  background-color: #000; /* Black button color */
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.create-contract-save-button:hover,
.create-contract-clear-button:hover,
.create-contract-submit-button:hover {
  background-color: #333; /* Darker shade on hover */
}

.contracts-list {
  margin-top: 30px;
}

.contract-item {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 15px;
  background-color: #fff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.contract-pdf {
  margin-top: 10px;
}

.contract-pdf h4 {
  margin-bottom: 5px;
}

.contracts-list {
  margin-top: 30px;
  padding: 20px;
  background-color: #f1f1f1; /* Light gray background for contrast */
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  display: flex; /* Use flexbox for layout */
  flex-direction: row;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  gap: 20px; /* Space between items */
}

.contract-item {
  flex: 1 1 calc(50% - 20px); /* Two items per row with some margin */
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 15px;
  background-color: #fff; /* White background for each contract item */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s; /* Smooth transition for hover effect */
}

.contract-item:hover {
  transform: translateY(-2px); /* Slight lift on hover */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
}

.contract-item p {
  margin: 5px 0; /* Spacing between paragraphs */
}

.contract-pdf {
  margin-top: 10px;
  width: 560px;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden; /* Ensures rounded corners for the iframe */
}

.contract-pdf h4 {
  margin-bottom: 5px;
  font-size: 16px;
  color: #333; /* Darker color for the heading */
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .contract-item {
    flex: 1 1 100%; /* Stack items on smaller screens */
  }
}


