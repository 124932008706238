/* AddGig.css */

.add-gig {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f4f4f9;
    font-family: Arial, sans-serif;
  }
  
  .add-gig form {
    background: #ffffff;
    padding: 20px;
    border-radius: 8px;
   
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    /* max-width: 500px; */
    width: 500px;
  }
  
  .add-gig h1 {
    margin-bottom: 20px;
    color: #333333;
    text-align: center;
  }
  
  .add-gig div {
    margin-bottom: 15px;
  }
  
  .add-gig label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555555;
  }
  
  .add-gig input,
  .add-gig textarea {
    width: calc(100% - 22px);
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.3s;
  }
  
  .add-gig input:focus,
  .add-gig textarea:focus {
    border-color: #666666;
  }
  
  .add-gig textarea {
    resize: vertical;
    min-height: 100px;
  }
  
  .add-gig button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #28a745;
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .add-gig button:hover {
    background-color: #218838;
  }
  
  .add-gig button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  