/* App.css */

@import url('https://fonts.googleapis.com/css?family=Quicksand:300,400,700');
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.6.1/css/font-awesome.min.css');

.login {
  /* background: rgba(47,100,90,1); */
  /* background: -moz-linear-gradient(left, rgba(47,100,90,1) 0%, rgba(111,161,123,1) 100%); */
  /* background: -webkit-linear-gradient(left, rgba(47,100,90,1) 0%, rgba(111,161,123,1) 100%); */
  /* background: -webkit-linear-gradient(left, rgb(0, 0, 0) 0%, rgb(255, 255, 255) 100%); */
background-color: black;
  margin: 0;
  padding: 0;
  height: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  font-family: 'Quicksand', sans-serif;
}

hr {
  border: 0;
  height: 1px;
  opacity: .3;
  /* background-image: linear-gradient(to right, rgba(255,255,255,.2), rgba(255,255,255,1), rgba(255,255,255,.2)); */
}

.login, .sign-up {
  opacity: 1;
  visibility: visible;
  position: absolute;
  z-index: 1000;
  top: 50%;
  left: 50%;
  margin-top: -230px;
  margin-left: -210px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: rgba(255,255,255,.3);
  height: 440px;
  width: 400px;
  color: black;
  text-align: center;
  border-radius: 2px;
  box-shadow: 10px 10px 15px -6px rgba(0,0,0,0.25);
  transition: opacity .3s ease-in .3s, visibility .3s ease-in .3s;
  transform: scale(.9);
}

.sign-up {
  height: 640px;
  z-index: 2000;
  top: 40%;
  left: 50%;
}

.login-closed {
  z-index: 2000;
  opacity: 0;
  visibility: hidden;
  margin-left: -400px;
  transition: opacity .3s ease-out, visibility .3s ease-out, margin .3s cubic-bezier(0.600, -0.280, 0.735, 0.045);
}

.sign-up-closed {
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  margin-left: -400px;
  transition: opacity .3s ease-out, visibility .3s ease-out, margin .3s cubic-bezier(0.600, -0.280, 0.735, 0.045);
}

.field {
  margin: 20px;
  margin-left: 50px;
  text-align: left;
}

.input {
  margin: 5px;
  width: 300px;
  margin-left: -5px;
  height: 40px;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  font-size: 20px;
  color: #646464;
  padding: 10px;
  outline: none;
  box-sizing: border-box;
}

.input:focus {
  border-color: #2f665a;
}

.label {
  font-size: 18px;
  font-weight: 700;
}

.field-in {
  opacity: 1;
  visibility: visible;
  transition: opacity .3s ease-in, visibility .3s ease-in;
}

.field-out {
  opacity: 0;
  visibility: hidden;
  transition: opacity .3s ease-out, visibility .3s ease-out;
}

.submit-button {
  background: #009B77;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  /* height: 50px; */
  /* width: 150px; */
  font-size: 20px;
  cursor: pointer;
}

.submit-button:hover {
  background: #007b65;
}

.in-out {
  font-size: 18px;
}

.signup-link {
  margin-top: 20px;
}

.signup-link a {
  color: #7ad02a;
  text-decoration: none;
}

.signup-link a:hover {
  color: #009b77;
  font-weight: bold;
}

.animate-check {
  color: green;
  margin-left: 5px;
  opacity: 0;
  transform: scale(0);
  transition: opacity .5s ease-in, transform .5s ease-in;
}

.input:focus + .label .animate-check {
  opacity: 1;
  transform: scale(1);
}



/* Dynamic Languages Section */
.language-container {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 15px;
}

.language-container input, .language-container select {
  flex: 1;
}

.add-language-button {
  background-color: #007b65;
  color: #fff;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.add-language-button:hover {
  background-color: #005a4a;
}

.remove-language-button {
  color: #ff5a5a;
  cursor: pointer;
}





.sign-up-container {
  /* background: white; */
  /* border-radius: 10px; */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  width: 100%;
margin-top: 50px;
  padding: 30px;
  text-align: center;
}

.sign-up-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #000000;
  margin-bottom: 20px;
}

.sign-up-divider {
  width: 50px;
  border: 2px solid #5b5bfa;
  margin: 10px auto;
  margin-bottom: 20px;
}

.sign-up-form {
  display: flex;
  flex-direction: column;
}

.sign-up-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.sign-up-field-firstname,
.sign-up-field-lastname,
.sign-up-field-password,
.sign-up-field-repassword {
  width: 48%;
}

.sign-up-input-email,
.sign-up-input-firstname,
.sign-up-input-lastname,
.sign-up-input-dob,
.sign-up-input-password,
.sign-up-input-repassword,
.sign-up-input-language-name {
  width: 100%;
  padding: 10px;
  margin: 12px 0;
  border-radius: 8px;
  border: 2px solid #ccc;
  font-size: 1rem;
  transition: border 0.3s ease-in-out;
}

.sign-up-input-email:focus,
.sign-up-input-firstname:focus,
.sign-up-input-lastname:focus,
.sign-up-input-dob:focus,
.sign-up-input-password:focus,
.sign-up-input-repassword:focus,
.sign-up-input-language-name:focus {
  border-color: #5b5bfa;
  outline: none;
}

.sign-up-btn-verify,
.sign-up-btn-send-otp,
.sign-up-btn-submit,
.sign-up-btn-add-language {
  background: #009B77;
  color: white;
  border: none;
  padding: 12px 20px;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
  margin: 10px 0;
}

.sign-up-btn-verify:hover,
.sign-up-btn-send-otp:hover,
.sign-up-btn-submit:hover,
.sign-up-btn-add-language:hover {
  background: #4a4ada;
}

.sign-up-btn-verify:active,
.sign-up-btn-send-otp:active,
.sign-up-btn-submit:active,
.sign-up-btn-add-language:active {
  background: #3737d2;
}

.sign-up-select-gender,
.sign-up-select-country,
.sign-up-select-proficiency {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: 2px solid #ccc;
  font-size: 1rem;
  background-color: white;
  transition: border 0.3s ease-in-out;
}

.sign-up-select-gender:focus,
.sign-up-select-country:focus,
.sign-up-select-proficiency:focus {
  border-color: #5b5bfa;
  outline: none;
}

.sign-up-field-gender,
.sign-up-field-country,
.sign-up-field-dob,
.sign-up-field-languages {
  margin-bottom: 20px;
}

.sign-up-label {
  text-align: left;
  font-size: 1.1rem;
  font-weight: 600;
  color: #444;
  margin-bottom: 8px;
}

.sign-up-language-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.sign-up-remove-language-btn {
  cursor: pointer;
  color: #ff4d4d;
  font-size: 1.5rem;
  transition: transform 0.3s ease;
}

.sign-up-remove-language-btn:hover {
  transform: scale(1.2);
}

.sign-up-link-toggle {
  font-size: 1rem;
  color: #353536;
  text-decoration: none;
  cursor: pointer;
  margin-top: 20px;
  background: none;
  border: none;
}


.sign-up-row {
  display: flex;
  gap: 20px; /* Adjust the gap between the fields */
}

.sign-up-row > div {
  flex: 1; /* Ensures equal width for each field */
}

.sign-up-field-gender,
.sign-up-field-country,
.sign-up-field-dob {
  display: flex;
  flex-direction: column;
}

.sign-up-select-gender,
.sign-up-select-country,
.sign-up-input-dob {
  padding: 10px;
  margin-top: 5px;
}


/* Modal background overlay */
.unique-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Darker overlay with higher opacity */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  transition: opacity 0.4s ease;
}

/* Modal container */
.unique-popup-modal {
  background: linear-gradient(145deg, #2a2a2a, #121212); /* Dark gradient for depth */
  border-radius: 12px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3); /* Deep shadow for a floating effect */
  width: 80%;
  max-width: 600px;
  padding: 30px;
  color: #f1f1f1; /* Light text for contrast */
  transform: translateY(-50px);
  animation: modalFadeIn 0.4s ease-out forwards;
}

/* Modal content */
.unique-popup-content {
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: center;
}

/* Modal title */
.unique-popup-title {
  font-size: 1.7rem;
  font-weight: 700;
  color: #fff;
  text-align: center;
  margin: 0;
  padding: 12px 0;
  border-bottom: 3px solid #333; /* Dark border to separate title */
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5); /* Subtle shadow for a glowing effect */
}

/* Popup message text */
.unique-popup-message {
  font-size: 1.2rem;
  color: #ccc;
  text-align: center;
  line-height: 1.7;
  margin-bottom: 25px;
  text-shadow: 0px 1px 5px rgba(0, 0, 0, 0.3); /* Soft shadow for text legibility */
}

/* Close button */
.unique-popup-close-btn {
  padding: 12px 25px;
  background-color: #333; /* Dark background */
  color: #f1f1f1; /* Light text */
  font-size: 1.1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  align-self: center;
  transition: background-color 0.3s, transform 0.3s ease-in-out, box-shadow 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Light shadow for depth */
}

/* Hover and focus states for the button */
.unique-popup-close-btn:hover {
  background-color: #444; /* Darker shade on hover */
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Stronger shadow on hover */
}

.unique-popup-close-btn:focus {
  outline: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6); /* Focus state with stronger shadow */
}

/* Fade-in animation for modal */
@keyframes modalFadeIn {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Adding smooth transition for the modal overlay */
.unique-popup-overlay {
  transition: opacity 0.4s ease-in-out;
}
