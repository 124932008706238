.app {
    display: flex;
  }
  
  .sidebar {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 78px;
    background: #11101d;
    padding: 6px 14px;
    z-index: 99;
    transition: all 0.5s ease;
  }
  
  .sidebar.open {
    width: 250px;
  }
  
  .content {
    margin-left: 78px;
    /* padding: 20px; */
    transition: all 0.5s ease;
  }
  
  .sidebar.open ~ .content {
    margin-left: 250px;
  }
  
  /* Additional styles */
  .content h1 {
    /* font-size: 24px; */
    /* font-weight: bold; */
  }
  
  /* .content p {
    font-size: 18px;
  } */
  

  .code-inputs {
    display: flex;
    gap: 8px; /* Adjust as needed */
    justify-content: center;
  }
  
  .code-box {
    width: 40px; /* Adjust as needed */
    height: 40px; /* Adjust as needed */
    text-align: center;
    font-size: 18px; /* Adjust as needed */
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  