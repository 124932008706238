/* TotpPage.css */

body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  /* background: linear-gradient(135deg, #6dd5ed, #2193b0); */
  display:flex;
 
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
}

.totp-container {
  /* background-color: white; */
 
 margin-top: 100px ;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  padding: 30px 40px;
  /* width: 350px; */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
}

h1 {
  margin-bottom: 20px;
  color: #333;
  font-size: 24px;
}

.totp-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.otp-inputs {
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-bottom: 25px;
}

.otp-box {
  width: 48px;
  height: 48px;
  text-align: center;
  border: 2px solid #ccc;
  border-radius: 6px;
  font-size: 20px;
  transition: border-color 0.3s;
}

.otp-box:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.totpbutton {
  padding: 12px 25px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  /* border-radius: 6px; */
  cursor: pointer;
  transition: background-color 0.3s;
}

.totpbutton:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-bottom: 15px;
}
