/* Modal overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  /* Modal content */
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    max-width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
  }
  
  /* Modal title */
  .modal-title {
    font-size: 20px;
    margin-bottom: 15px;
    font-weight: bold;
    color: #333;
  }
  
  /* Form group for inputs */
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    font-size: 14px;
    color: #555;
    margin-bottom: 5px;
  }
  
  .modal-select,
  .modal-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* Modal buttons */
  .modal-button {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .modal-button:hover {
    background-color: #0056b3;
  }
  
  /* Close button */
  .modal-button.modal-close {
    background-color: #dc3545;
  }
  
  .modal-button.modal-close:hover {
    background-color: #c82333;
  }
  
  /* Success message */
  .success-message {
    margin-top: 15px;
    color: green;
    font-weight: bold;
    text-align: center;
  }
  